import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorReportingService } from "./error-reporting.service";

@Injectable({
	providedIn: "root",
})
export class UnhandledErrorCaptureService implements ErrorHandler {
	constructor(private readonly errorReporting: ErrorReportingService) {}

	async handleError(error: unknown) {
		console.error("[UNHANDLED_ERROR]", error);

		await this.errorReporting.report(error, {
			sbErrorCode: "unhandled",
			severity: "ERROR",
			alertMessage: "Something went wrong.",
			internalMessage: "Unhandled error from Custom error handler",
		});
	}
}
