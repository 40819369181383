import { ErrorHandler, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
	AuthGuard,
	redirectUnauthorizedTo,
	redirectLoggedInTo,
} from "@angular/fire/auth-guard";
import { AccessGuard } from "./access.guard";
import { DashGuard } from "./dash-guard.guard";
import { UnhandledErrorCaptureService } from "./error-reporting/unhandled-error-capture.service";

const redirectLoggedInToSelectShop = () => redirectLoggedInTo(["select-shop"]);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["sign-in"]);

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		title: "Storebase - Smart modern easy to use billing software.",
		loadComponent: () =>
			import("./landing/landing.component").then((m) => m.LandingComponent),
		canActivate: [AuthGuard],
		data: { authGuardPipe: redirectLoggedInToSelectShop },
	},
	{
		path: "sign-in",
		title: "Sign In - Storebase Accounts",
		loadChildren: () =>
			import("./sign-in-new/sign-in.routing").then((m) => m.signInRoute),
	},
	{
		path: "select-shop",
		title: "Select Shop - Storebase",
		loadComponent: () =>
			import("./select-shop/select-shop.component").then(
				(m) => m.SelectShopComponent,
			),
		canActivate: [AuthGuard, AccessGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: "create-new-shop",
		title: "Create Shop - Storebase",
		loadComponent: () =>
			import("./create-new-shop/create-new-shop.component").then(
				(m) => m.CreateNewShopComponent,
			),
		canActivate: [AuthGuard, AccessGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: "dash/:shopId",
		loadChildren: () => import("./dash/dash.module").then((m) => m.DashModule),
		canActivate: [AuthGuard, AccessGuard, DashGuard],
		data: {
			authGuardPipe: redirectUnauthorizedToLogin,
		},
	},
	{
		path: "privacy-terms",
		title: "Privacy Terms - Storebase",
		pathMatch: "full",
		loadComponent: () =>
			import("./privacy-terms/privacy-terms.component").then(
				(m) => m.PrivacyTermsComponent,
			),
	},
	{
		path: "profile-edit",
		title: "Edit Profile - Storebase",
		pathMatch: "full",
		loadComponent: () =>
			import("./profile-edit/profile-edit.component").then(
				(m) => m.ProfileEditComponent,
			),
	},
	{
		path: "**",
		title: "Page Not Found - Storebase",
		pathMatch: "full",
		loadComponent: () =>
			import("./page-not-found/page-not-found.component").then(
				(m) => m.PageNotFoundComponent,
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [
		{ provide: ErrorHandler, useClass: UnhandledErrorCaptureService },
	],
})
export class AppRoutingModule {}
