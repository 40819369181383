import { importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import {
	provideStorage,
	getStorage,
	connectStorageEmulator,
} from "@angular/fire/storage";
import {
	provideRemoteConfig,
	getRemoteConfig,
} from "@angular/fire/remote-config";
import {
	provideFunctions,
	getFunctions,
	connectFunctionsEmulator,
} from "@angular/fire/functions";
import {
	provideFirestore,
	getFirestore,
	connectFirestoreEmulator,
} from "@angular/fire/firestore";
import { provideAuth, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import { environment } from "./environments/environment";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import {
	withInterceptorsFromDi,
	provideHttpClient,
} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { httpAuthInterceptorProvider } from "./app/auth-interceptor.service";

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			AppRoutingModule,
			provideFirebaseApp(() => initializeApp(environment.firebase)),
			provideAnalytics(() => getAnalytics()),
			provideAuth(() => {
				const auth = getAuth();
				if (environment.useEmulators) {
					connectAuthEmulator(auth, "http://0.0.0.0:9099", {
						disableWarnings: true,
					});
				}
				return auth;
			}),
			provideFirestore(() => {
				const firestore = getFirestore();
				if (environment.useEmulators) {
					connectFirestoreEmulator(firestore, "0.0.0.0", 8080);
				}
				return firestore;
			}),
			provideFunctions(() => {
				const functions = getFunctions(undefined, environment.gcpRegion);
				if (environment.useEmulators) {
					connectFunctionsEmulator(functions, "0.0.0.0", 5001);
				}
				return functions;
			}),
			provideRemoteConfig(() => getRemoteConfig()),
			provideStorage(() => {
				const storage = getStorage();
				if (environment.useEmulators) {
					connectStorageEmulator(storage, "0.0.0.0", 9199);
				}
				return storage;
			}),
		),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		httpAuthInterceptorProvider,
	],
}).catch((err) => console.error(err));
