import { Injectable, Provider } from "@angular/core";
import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { catchError, finalize, switchMap, take } from "rxjs";
// Injection token for the Http Interceptors multi-provider
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GoogleUserService } from "./auth/google-user.service";
import { ErrorReportingService } from "./error-reporting/error-reporting.service";
import { ProgressService } from "./progress.service";

@Injectable()
class AuthInterceptor implements HttpInterceptor {
	constructor(
		private readonly googleUser: GoogleUserService,
		private readonly errorReporting: ErrorReportingService,
		private readonly progress: ProgressService,
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler) {
		const stopper = this.progress.start(
			"intercept-" + Math.random().toString(),
		);
		// Get the auth token from the service.
		return this.googleUser.googleUser$.pipe(
			take(1),
			switchMap((user) => {
				if (!user) return Promise.resolve(null);

				return user.getIdToken();
			}),
			switchMap((authToken) => {
				if (!authToken) return next.handle(req);

				// Clone the request and replace the original headers with
				// cloned headers, updated with the authorization.
				const authReq = req.clone({
					headers: req.headers.set("Authorization", `Bearer ${authToken}`),
				});

				// send cloned request with header to the next handler.
				return next.handle(authReq).pipe(
					catchError(async (error: unknown) => {
						await this.errorReporting.report(error, {
							moreJson: {
								request: authReq,
								response: error,
							},
						});
						throw error;
					}),
				);
			}),
			finalize(() => {
				stopper();
			}),
		);
	}
}

export const httpAuthInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true,
};
