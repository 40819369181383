import { Injectable } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { GoogleUserService } from "./auth/google-user.service";
import { ShopIdService } from "./dash/shop-id.service";

@Injectable({
	providedIn: "root",
})
export class DashGuard {
	constructor(
		private readonly router: Router,
		private readonly shopId: ShopIdService,
		private readonly googleUser: GoogleUserService,
	) {}

	canActivate: CanActivateFn = async (route) => {
		const shopId = route.paramMap.get("shopId");

		if (!shopId) {
			this.router.navigate(["select-shop"]);
			return false;
		}

		const shopIdHandling = this.shopId.shopIdObs$.next(shopId);

		{
			// lazy check
			const tokenResult = await this.googleUser.getIdTokenResult();

			if (tokenResult.claims[shopId]) {
				await shopIdHandling;
				return true;
			}
		}

		{
			// check again force
			const tokenResult = await this.googleUser.getIdTokenResult(true);

			if (tokenResult.claims[shopId]) {
				await shopIdHandling;
				return true;
			}
		}

		await shopIdHandling;
		await this.router.navigate(["select-shop"]);
		return false;
	};
}
