import { Injectable } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { environment } from "../environments/environment";
import {
	CanUserUseStorebaseGetResponse,
	WaitingListCreateResponse,
} from "@storebase/api-bridge";
import { lastValueFrom, switchMap, take } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GoogleUserService } from "./auth/google-user.service";
import { SignOutService } from "./auth/sign-out.service";

@Injectable({
	providedIn: "root",
})
export class AccessGuard {
	constructor(
		private readonly router: Router,
		private readonly googleUser: GoogleUserService,
		private http: HttpClient,
		private readonly signOut: SignOutService,
	) {}

	canActivate: CanActivateFn = () => {
		return this.googleUser.googleUser$.pipe(
			switchMap(async (user) => {
				if (!user) {
					return false;
				}

				const userEmail = user.email;
				const userPhone = user.phoneNumber;

				const responses = await Promise.all([
					lastValueFrom(
						this.http
							.get<CanUserUseStorebaseGetResponse>(
								`${environment.mainServer.baseUrl}/canUsePlatform?key=${encodeURIComponent(userPhone ?? "")}`,
							)
							.pipe(take(1)),
					),
					lastValueFrom(
						this.http
							.get<CanUserUseStorebaseGetResponse>(
								`${environment.mainServer.baseUrl}/canUsePlatform?key=${userEmail ?? ""}`,
							)
							.pipe(take(1)),
					),
				]).catch(async () => {
					await this.signOut.signOut();
					return await this.router.navigate(["sign-in"]);
				});

				if (!Array.isArray(responses)) return false;

				const canAccess =
					responses[0].canUsePlatform || responses[1].canUsePlatform;

				if (!canAccess) {
					const waitingList$ = this.http.post<WaitingListCreateResponse>(
						`${environment.mainServer.baseUrl}/waitingList`,
						{
							email: userEmail ?? "",
							phone: userPhone ?? "",
							employees: 0,
							shopName: "",
							hasJoinedWaitList: false,
						},
					);

					const waitingList = await lastValueFrom(waitingList$.pipe(take(1)));

					if (waitingList.status) {
						console.log("waiting list created");
					}

					this.router.navigate(["no-access"]);
				}

				return canAccess;
			}),
		);
	};
}
